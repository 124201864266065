import React, { useState } from "react";

import DefaultLayout from "../../../layouts/default";

const LE = () => {
  const [akkordeonOpen, setAkkordeonOpen] = useState(false);
  const [akkordeon2Open, setAkkordeon2Open] = useState(false);
  return (
    <DefaultLayout title="WiCare|now LE">
      <div className="white wicare vanadium">
        <div className="navContentSpacerHelper"></div>
        <header className="navWrapper">
          <nav className="mainNav">
            <div className="mainNavBackgroundHelper"></div>
            <div className="logo">
              <a href="/">
                <img
                  src="/assets/images/logos/wigasoft-logo.svg"
                  alt="WigaSoft Logo"
                  width="175"
                />
              </a>
            </div>
            <div className="mainNav-opener"></div>
            <ul className="mainLinks">
              <li>
                <a href="/news/">News</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/heime/">Heime</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/rehabilitation/">
                  Rehabilitation
                </a>
              </li>
              <li>
                <a href="/portrait/">Über uns</a>
              </li>
              <li>
                <a href="/support/">Support</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/wigasoft-ag">
                  <div className="linkedInIcon" />
                </a>
              </li>
            </ul>
          </nav>

          <nav className="subNav2">
            <div className="subNav2Product">
              <div className="productImage">
                <img
                  src="/assets/images/logos/WCN-LE.svg"
                  alt="WiCare|Doc Logo"
                  width="40"
                />
              </div>
              <div className="productText">
                <span className="line">WiCare|now LE</span>
                <span className="line">
                  <span className="is-visible-desktop">&nbsp;Dezentrale</span>
                  &nbsp;Leistungserfassung
                  <span className="is-visible-desktop">
                    &nbsp;auf Knopfdruck
                  </span>
                </span>
              </div>
            </div>
            <div className="subNav2-opener"></div>
            <ul className="subNav2Links">
              <li className="is-active">
                <a href="/dokumentationsloesungen/wicare-le/">Übersicht</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-le/technik/">
                  Technik
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-le/referenzen/">
                  Referenzen
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-le/downloads/">
                  Downloads
                </a>
              </li>
            </ul>
          </nav>
        </header>

        <div className="menuBackground">
          <section className="mainBanner bannerWeiss">
            <div className="wrapper has-padding-top has-padding-bottom">
              <div className="bannerFlex">
                <div>
                  <img
                    className="logo-product"
                    src="/assets/images/logos/WCN-LE.svg"
                  />
                </div>
                <div className="bannerPadding">
                  <h1>WiCare|now LE</h1>
                  <h3 className="padding-25-bottom">
                    Dezentrale Leistungserfassung auf Knopfdruck
                  </h3>
                  <p className=" bannerText">
                    Die webbasierte Leistungserfassungslösung für verschiedene
                    Fachbereiche.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <div className="wrapper">
            <section>
              <h3 className="smallStaticPadding-bottom h3Bold text-centered-td">
                WiCare|now LE
              </h3>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                Die TARMED-Einführung im Jahr 2003 war gleichzeitig die
                Geburtsstunde unserer "VANADIUM-Suite" mit der
                Leistungserfassung WigAgent. Diverse Zusatzmodule deckten die
                Bedürfnisse der verschiedenen Fachbereiche ab.
              </p>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                Nach knapp 20 Jahren wird unsere bewährte VANADIUM-Suite nun
                durch die neue, webbasierte Generation WiCare|now LE abgelöst.
              </p>
              <p className="fliesstextLead text-centered-td">
                Mit WiCare|now LE können Leistungen für die interne
                Kostenrechnung sowie für die Fakturierung erfasst werden. Die
                Regelwerke der verwendeten Tarife werden berücksichtigt. Die
                Benutzung der Applikation ist mit einem minimalen
                Schulungsaufwand der Anwender möglich.
              </p>
            </section>
          </div>

          <div className="wrapper mobile-tablet-no-padding">
            <section className="cContainer is-background-color-red is-color-white rounded">
              <h4 className="smallStaticPadding-bottom text-centered-td h4Bold">
                Best Features
              </h4>
              <div className="ccHalfFlex">
                <div>
                  <ul className="featuresList">
                    <li>Webbasierte, plattformunabhängige Software-Lösung</li>
                    <li>
                      Regelwerke der verwendeten Tarife berücksichtigt (TARMED
                      1.09 von WigaSoft vorkonfiguriert)
                    </li>
                    <li>
                      Schnittstellen zu ERP-Systemen (u.a. Übernahme Material-
                      und Medikamentenkatalog, Physio- und andere
                      Therapie-Tarife, betriebsinterne statistischer Leistungen)
                      erfolgreich im Einsatz
                    </li>
                    <li>
                      bietet verschiedene Erfassungsmöglichkeiten (z.B.
                      Einzelleistungen, individuelle Blöcke)
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="featuresList">
                    <li>rollenbasierte Zugriffe auf Funktionalitäten</li>
                    <li>
                      mit weiteren WiCare|now-Komponenten erweiterbar (z.B.
                      Anästhesie, AQC, CHOP-Zusatzerfassung)
                    </li>
                    <li>
                      Leistungsdaten aus der elektronischen Pflegedokumentation
                      WiCare|Doc oder anderen Klinikinformationssystemen können
                      übernommen werden
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          <div className="wrapper wrapper-xl">
            <section className="margin-45-bottom">
              <div
                className={
                  akkordeonOpen
                    ? "funktionenToggle is-background-color-red selected"
                    : "funktionenToggle is-background-color-red"
                }
                onClick={() => setAkkordeonOpen(!akkordeonOpen)}
              >
                <h3>Funktionen</h3>
                <img src="/assets/images/gestaltungselemente/akkordeon-arrow.svg" />
              </div>
              <div>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/le/2021/Bild_1_Leistungsblock.png?auto=format,compress"
                      data-lightbox="le-1"
                      data-title=""
                      data-actual-title="Leistungserfassung (TARMED 1.09 vorkonfiguriert)"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/le/2021/Bild_1_Leistungsblock.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">
                      Leistungserfassung (TARMED 1.09 vorkonfiguriert)
                    </h4>
                    <p className="fliesstext">
                      Die Leistungserfassung kann über Navigation in den
                      Belegen, über Eingabe der Leistungsnummer (Tastatur) oder
                      Scanner erfolgen. Ebenfalls können individuelle
                      Leistungsblöcke erstellt werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/le/2021/Bild_2_Erfassungsboard.png?auto=format,compress"
                      data-lightbox="le-1"
                      data-title=""
                      data-actual-title="Leistungserfassung (weitere Kataloge/Regelwerke)"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/le/2021/Bild_2_Erfassungsboard.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">
                      Leistungserfassung (weitere Kataloge/Regelwerke)
                    </h4>
                    <p className="fliesstext">
                      Verschiedene Regelwerke können konfiguriert werden.
                      <br />
                      <br />
                      Material- und Medikamentenkataloge
                      <br />
                      Physio- und andere Therapie-Tarife
                      <br />
                      Honorarleistungen
                      <br />
                      betriebsinterne statistische Leistungen
                      <br />
                      weitere Tarife, deren Regelwerk im Rahmen der oben
                      aufgeführten Kataloge liegt
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
              </div>
            </section>
          </div>

          <section className="nutzen">
            <div
              className={
                akkordeon2Open
                  ? "funktionenToggle is-background-color-red selected"
                  : "funktionenToggle is-background-color-red"
              }
              onClick={() => setAkkordeon2Open(!akkordeon2Open)}
            >
              <h3>Nutzen</h3>
              <img src="/assets/images/gestaltungselemente/akkordeon-arrow.svg" />
            </div>
            <div>
              <div className="wrapper">
                <div className="selection has-padding-top">
                  <div className="tab-select selected" id="select1">
                    <h4>User</h4>
                  </div>
                  <div className="tab-select" id="select2">
                    <h4>Management</h4>
                  </div>
                  <div className="tab-select" id="select3">
                    <h4>IT</h4>
                  </div>
                </div>
              </div>
              <div className="content is-background-color-grey">
                <div className="wrapper">
                  <div
                    id="content1"
                    className="selected has-padding-top has-padding-bottom"
                  >
                    <div>
                      <h4 className="h4Bold">Übersichtlich und klar</h4>
                      <p className="fliesstext7">
                        Die Mitarbeiter können die täglich erbrachten Leistungen
                        übersichtlich, klar und effizient erfassen.
                      </p>
                    </div>
                    <div>
                      <ul className="featuresList">
                        <li>
                          Individuelle Leistungs-Blöcke/-Pakete sind
                          konfigurierbar, Standard- und Auswahlleistungen können
                          vom Anwender verändert werden
                        </li>
                        <li>für mobile Geräte (Tablets) optimiert</li>
                        <li>
                          Übernahme der dokumentierten Handlungen aus WiCare|now
                          Doc und anderen Klinikinformationssystemen
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    id="content2"
                    className="has-padding-top has-padding-bottom"
                  >
                    <div>
                      <h4 className="h4Bold">Integrierbar</h4>
                      <p className="fliesstextLead">
                        WiCare|now LE liefert Daten für die interne
                        Kostenrechnung sowie für die Fakturierung.
                      </p>
                    </div>
                    <div>
                      <ul className="featuresList">
                        <li>
                          Webbasierend, Geräte- und Betriebssystemunabhängig
                        </li>
                        <li>
                          Lösung auf dem aktuellsten Stand der Software-Technik
                        </li>
                        <li>
                          Leistungsvalidierung und Plausibilitätsprüfungen
                        </li>
                        <li>
                          Einfache Verwaltung und minimaler Erfassungsaufwand
                        </li>
                        <li>
                          URL-Integration in Drittprodukten, kein Wechsel der
                          Applikation notwendig
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    id="content3"
                    className="has-padding-top has-padding-bottom"
                  >
                    <div>
                      <h4 className="h4Bold">Standardisiert und normiert</h4>
                      <p className="fliesstextLead">
                        WiCare|now LE passt sich in die vorgegebene
                        IT-Landschaft ein. Die Orientierung an gängigen
                        Standards wie z.B. ALIS und HL7 hilft bei der einfachen
                        Integration von Dritt-Systemen. Die Kompatibilität mit
                        den neusten Windows Server- und Client-Betriebssystemen
                        sowie den aktuellen Versionen von Microsoft SQL Server
                        und Datenbanksysteme erlauben den Betrieb stabiler und
                        ausbaufähiger Systeme, die sich nahtlos in
                        Standard-Netzwerke einbinden lassen.
                      </p>
                    </div>
                    <div>
                      <ul className="featuresList">
                        <li>
                          Webbasierend, Geräte- und Betriebssystemunabhängig
                        </li>
                        <li>für mobile Geräte (Tablets) optimiert</li>
                        <li>
                          Aufruf aus oder Integration in Drittapplikationen via
                          URL möglich
                        </li>
                        <li>ActiveDirectory und SingleSignOn unterstützt</li>
                        <li>
                          verfügt mit WiCare|now Admin über ein einfach zu
                          bedienendes Konfigurationsmodul
                        </li>
                        <li>
                          stellt ein flexibel konfigurierbares
                          Schnittstellenmodul bereit
                        </li>
                        <li>
                          basiert auf einer strukturiert aufgebauten Datenbank
                          mit einfach lesbarem Datenmodell
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="wrapper">
              <div className="bContainer red margin-45-bottom">
                <h5>Bemerkenswert</h5>
                <p>
                  Die äusserst flexible Parametrierbarkeit ermöglicht die
                  Erfassung unterschiedlichster Leistungen – effizient und
                  einfach. Die Regelwerke der verwendeten Tarife werden
                  berücksichtigt (TARMED 1.09 wird vorkonfiguriert
                  ausgeliefert).
                </p>
              </div>
            </div>
          </section>

              <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

          <footer className="mainFooter">
            <div className="wrapper">
              <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
                <ul>
                  <li>
                    <a href="/">Startseite</a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footerNotAnotherFlexboxButAGrid linklist">
                <div className="flex-item1">
                  <ul>
                    <li>
                      <a href="/dokumentationsloesungen/heime/">
                        <strong>Heime</strong>
                      </a>
                    </li>
                    <li>
                      <a href="/dokumentationsloesungen/spitaeler/">
                        <strong>Spitäler</strong>
                      </a>
                    </li>
                    <li>
                      <a href="/dokumentationsloesungen/rehabilitation/">
                        <strong>Rehabilitation</strong>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item2">
                  <ul>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-l/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -L BESA
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -L RAI-NH
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-b/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -B
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-h/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -H
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-r/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -R
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item3">
                  <ul>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-tacs/">
                        WiCare|now tacs<sup>&#174;</sup>
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-lep/">
                        WiCare|now LEP<sup>&#174;</sup>
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-le/">
                        WiCare|now LE
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item4">
                  <ul>
                    <li>
                      <a href="/portrait/">
                        <strong>Über uns</strong>
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/portrait/lehrlinge/">Lernende</a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/portrait/partner/">Partner</a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                    </li>
                    <li>
                      <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item5">
                  <ul>
                    <li>
                      <a href="/support/">
                        <strong>Support</strong>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row copyright">
              <div className="wrapper">
                <div className="column">
                  © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
                </div>
                <div className="column has-padding-left">
                  <ul>
                    <li>
                      <a href="/datenschutz/">Datenschutzerklärung</a>
                    </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                  </ul>
                </div>
                <div className="column align-right">
                  <a href="/portrait/kontakt/" className="anfahrt">
                    <img
                      id="location-pointer"
                      src="/assets/images/icons/icon-anfahrt.svg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default LE;
